import { defineStore } from 'pinia'

export const useCustomerStore = defineStore('customer', {
  state: () => {
    return {
      users: [],
      access: [],
      error: null,
      loading: true
    }
  },

  actions: {
    async getUsers() {
      try {
        const response = await handleGet({ url: '/frontend/customer/users' })
        this.users = response.data
      } catch (error) {
        this.error = error
      } finally {
        this.loading = false
      }
    },

    async getCustomerAccess() {
      try {
        const response = await handleGet({ url: '/frontend/customer/access' })

        const values = response.data

        this.access = values
      } catch (ex) {
        console.log(ex)
      }
    },

    async createUser(firstName, lastName, email) {
      await handlePost({
        url: `/frontend/user/create`,
        body: {
          firstName,
          lastName,
          email: email
        },
        errMsg: 'Failed to add user',
        successMsg: 'New user added'
      })

      await this.getUsers()
    },

    async updateUser(id, name, email) {
      await handlePost({
        url: `/frontend/user/update`,
        body: {
          userId: id,
          userName: name,
          email: email
        },
        errMsg: 'Failed to update user',
        successMsg: 'User updated'
      })

      await this.getUsers()
    },

    async deleteUser(id, email) {
      await handlePost({
        url: `/frontend/user/delete`,
        body: {
          userId: id,
          email
        },
        errMsg: 'Failed to delete user',
        successMsg: 'User deleted'
      })

      await this.getUsers()
    },

    async setAdmin(id) {
      await handlePost({
        url: `/frontend/customer/users/admin`,
        body: {
          userId: id,
        },
        errMsg: 'Failed to upgrade user',
        successMsg: 'User set as admin'
      })

      await this.getUsers()
    }
  }
})
